/*@font-face {
  font-family: 'Sofia';
  src: url(./font/Sofia_Pro/Sofia_Pro_Regular.otf);
}

@font-face {
  font-family: 'Sofia_Bold';
  src: url(./font/Sofia_Pro/Sofia_Pro_Bold.otf);
}

@font-face {
  font-family: 'Sofia_Light';
  src: url(./font/Sofia_Pro/Sofia_Pro_Light.otf);
}*/

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

html,
body {
  font-family: "Lato";
}

hr {
  width: 40%;
  border: 3px solid #26c4f0;
  border-radius: 5px;
}
.special_hr {
  width: 100%;
}

ul {
  padding-left: 18px;
}

.hide {
  display: none;
}
.mainSection {
  background-color: black;
  padding-top: 80px;
  padding-bottom: 80px;
}
.blackSection {
  background-color: black;
}
.graySection {
  background-color: #f3f3f3;
  padding-top: 0px;
  padding-bottom: 0px;
}
.mainBtn {
  background-color: #26c4f0 !important;
  color: #000 !important;
}
.mainBtn-orange {
  background-color: orange !important;
  color: #fff !important;
}
.mainBtn-aws {
  background-color: #5f5e5f !important;
  color: #f7ab0e !important;
}
.awsBtn {
  background-color: #555 !important;
  color: #000;
}

.mainLeft {
  text-align: center;
}
.mainTitle {
  font-size: 42px;
  font-family: "Lato";
  font-weight: 700;
  color: #fff;
  padding: 0 30px;
}
.mainDesc {
  font-size: 15px;
  color: #fff;
}
.mainBG {
  max-width: 100%;
  position: relative;
}
.backgroundLogo {
  background-image: url("./img/logo_bk.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
}
.backgroundLogoSimple {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: -webkit-fill-available;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
}
.circle {
  width: 325px;
  height: 325px;
  /*line-height: 500px;*/
  border-radius: 50%;
  padding: 54px;
  color: #000;
  text-align: center;
  background: #26c4f0;
  font-size: 30px;
  font-weight: 700;
}
.moduleContent {
  font-size: 22px;
  /*padding: 25px;*/
  position: relative;
}
.moduleVertical {
  font-size: 25px;
  position: relative;
}
.secondLeft {
  padding: 10px;
  position: relative;
}
.sectionTitle {
  font-size: 50px;
  font-family: "Lato";
  font-weight: 700;
}
.sectionDesc {
  width: 70%;
  font-size: 28px;
}
.sectionDescAlt {
  width: 70%;
  font-size: 40px;
}
.dataTitle {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Lato";
  font-weight: 700;
  color: #26c4f0;
}
.dataDesc {
  font-size: 18px;
}
.dataDescBigger {
  font-size: 22px;
}
.dataDescBiggest {
  font-size: 1.5em;
}
.dataDescSmaller {
  font-size: 1em;
}
.fixed_height_div {
  height: 220px;
}
.blue-background {
  background-color: #26c4f0;
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  height: 75%;
}
.background-box {
  background-color: #000;
  color: #fff;
  padding: 30px 50px 30px 50px;
  position: relative;
  top: -20px;
  transition: top ease 0.1s;
  -webkit-box-shadow: 2px 2px 19px -5px #000000;
  box-shadow: 2px 2px 19px -5px #000000;
}
.background-box:hover {
  top: -25px;
}
.image-box {
  position: relative;
  z-index: 1;
}
.background-box2 {
  background-color: #edeaea;
  padding: 250px 50px 30px 50px;
  position: relative;
}
.image-box2 {
  position: relative;
  z-index: 1;
}
.box-padding {
  padding: 10px 10px 25px 10px;
  position: relative;
}
.background-box3 {
  background-color: #edeaea;
  padding: 30px;
  position: relative;
  text-align: left;
  height: auto;
}
.demo-style {
  padding: 5px 10%;
}
.hoverText {
  visibility: visible;
}
.hoverText:hover {
  visibility: visible;
}
.hidden-iamge {
  visibility: hidden;
}
.btn-action {
  /*position: absolute;
  bottom: 5%;
  left: 15%;
  right: 15%;*/
}
.icon-style {
  font-size: 14px;
  position: relative;
  top: -1px;
}
.options-style {
  font-size: 14px;
  position: relative;
  top: -3px;
}
.request-modal {
  display: block;
}
.privacy {
  font-size: 11px;
}
.container-box {
  height: 350px;
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.v-center {
  margin: 0;
  position: absolute;
  top: 50%;
  padding-left: 15px;
  padding-right: 15px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-light-small {
  color: #fff;
  background-color: #000;
  border-color: transparent;
  font-size: 13px;
  font-family: "Lato";
  font-weight: 700;
}
.btn-small {
  padding: 8px 16px;
  border-radius: 2.25rem;
}

.btn-light {
  color: #fff;
  background-color: #000;
  border-color: #fff;
  font-size: 18px;
  font-family: "Lato";
  font-weight: 700;
}
.btn {
  padding: 10px 30px;
  border-radius: 2.25rem;
}

.col-md-4 {
  flex: 0 0 33.3333333%;
  max-width: 33.3333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

.policy-style {
  max-width: 50%;
}
.flag {
  width: 21px;
  position: relative;
  top: -2px;
}

.btn-holder {
  justify-content: center;
  display: flex;
}

.div-helper {
  height: 62px;
}
@media only screen and (max-width: 1200px) {
  .div-helper {
    height: 44px;
  }
}

@media only screen and (max-width: 990px) {
  .div-helper {
    height: 9px;
  }
}
@media only screen and (max-width: 800px) {
  .div-helper {
    height: 16px;
  }
  .mainSection {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .backgroundLogo {
    position: relative;
    padding-bottom: 20px;
  }
  .mainTitle {
    font-size: 26px;
    padding: 0 0;
  }
  .moduleContent {
    text-align: center;
  }
  .sectionDesc {
    width: 100%;
    font-size: 26px;
  }
  .blue-background {
    background-color: transparent;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .secondLeft {
    padding: 25px;
  }
  .sectionTitle {
    font-size: 30px;
  }
  .sectionText {
    font-size: px;
  }
  .circle {
    width: 300px;
    height: 300px;
    font-size: 26px;
  }
  .background-box {
    background-color: #000;
    padding: 20px 15px 20px 15px;
    text-align: center;
    color: #fff;
    height: auto;
  }
  .modal-footer {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-right: 0;
    padding-left: 0;
  }
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .image-box2 {
    position: relative;
  }
  .background-box3 {
    padding: 15px;
  }
  .hidden-iamge {
    display: none;
  }
  .btn-action {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .moduleVertical {
    text-align: center;
  }
  .sectionDescAlt {
    width: 100%;
  }
  .container-box {
    height: auto;
    position: relative;
  }

  .vertical-center {
    margin: 0;
    position: relative;
    top: 0%;
    left: 0%;
    -ms-transform: translate(0%);
    transform: translate(0%);
  }
  .v-center {
    margin: 0;
    position: relative;
    top: 50%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
    top: 0%;
    left: 0%;
    -ms-transform: translate(0%);
    transform: translate(0%);
  }
  .policy-style {
    max-width: 100%;
  }
  .backgroundLogoSimple {
    position: relative;
  }
}
